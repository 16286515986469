const crypto = require('crypto-browserify')
const cheerio = require('cheerio');

const scrapeMTGPirulo = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    // Scrape de las cartas
    $('.product').each((index, product) => {
        const expansion = $(product).find('.category').text().trim();
        const title = $(product).find('.name').text().trim();
        const imageUrl = $(product).find('.image img').attr('src');

        $(product).find('.variant-row').each((indexVariant, productVariant) => {
            const stockStatus = $(productVariant).find('.variant-qty').text().trim();
            if (stockStatus.toLowerCase().includes('out of stock')) {
                return; // Omitir este producto porque está agotado
            }
            const price = $(productVariant).find('.price').text().trim();
            const condition = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-variant').value
            const id = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-vid').value

            if (scrapedData.some(card => card.id === id)) {
                return; // Omitir este producto porque está repetido
            }


            scrapedData.push({
                id,
                title,
                price,
                imageUrl,
                condition,
                expansion,
                link: url
            });    
        });
    });    
    return scrapedData
};


const scrapeMagicDealers = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.product').each((index, product) => {
        const title = $(product).find('.name').text().trim();
        const imageUrl = $(product).find('.image img').attr('src');
        const expansion = $(product).find('.category').text().trim();
        $(product).find('.variant-row').each((indexVariant, productVariant) => {
            const stockStatus = $(productVariant).find('.variant-qty').text().trim();
        

            if (stockStatus.toLowerCase().includes('out of stock')) {
                return; // Skip this item because it's out of stock
            }
            const price = $(productVariant).find('.price').text().trim();
            const condition = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-variant').value
            // Create a unique ID by hashing the title and price
            const id = $(productVariant).find('.add-to-cart-form').get(0).attributes.find(obj => obj.name === 'data-vid').value

            if (scrapedData.some(card => card.id === id)) {
                return; // Omitir este producto porque está repetido
            }
    
            scrapedData.push({
                id, 
                title,
                price,
                imageUrl,
                expansion,
                condition,
                link: url
            });
        });
    });

    return scrapedData;
};

const scrapeMagicLair = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.collectionGrid .productCard__card').each((index, element) => {
        const title = $(element).find('.productCard__title a').text().trim();
        const expansion = $(element).find('.productCard__setName').text().trim();
        const imageUrl = $(element).find('.productCard__img').attr('data-src');
        const link = 'https://www.magiclair.com.ar' + $(element).find('.productCard__title a').attr('href');
        
        $(element).find('.productChip').each((indexVariant, productVariant) => {
            if (productVariant.attribs.class.includes('hidden')) {
                return; // Skip this item because it's out of stock
            }
            const price = productVariant.attribs['data-variantprice'] / 100;
            const condition = productVariant.attribs['data-varianttitle'];
            const id = productVariant.attribs['data-variantid'];
            scrapedData.push({
                id,
                title,
                price,
                expansion,
                condition,
                imageUrl,
                link,
            });
        });
    });
    return scrapedData;
};


const scrapeLaBatikuevaStore = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];

    $('.js-product-container').each((index, element) => {
        // Check stock status
        const isOutOfStock = $(element).find('.label-default').text().trim() === 'Sin stock';
        if (isOutOfStock) {
            return; // Skip this item because it's out of stock
        }

        const title = $(element).find('.js-item-name').text().trim();

        // Extract and handle image URL
        const imageUrlRelative = $(element).find('.js-item-image').attr('data-srcset').split(' ')[0];
        const imageUrl = `https:${imageUrlRelative}`; // Assuming it needs 'https:' prefix

        // Extract price (modify selector if necessary)
        const price = $(element).find('.item-price').text().trim() || '$No muestra precio'; // Replace with actual logic to extract price

        const link = $(element).find('.item-link').attr('href');
        const id = crypto.createHash('sha256').update($(element).html()).digest('hex');
        const variants = []
        $(element).find('.js-variation-option').each((index, variant) => { 
            $(variant).find('option').each((optionIndex, option) => variants.push($(option).html()));
        });
        const condition = variants.join(', ');
        scrapedData.push({
            id,
            title,
            price,
            expansion: ' ',
            condition,
            imageUrl,
            link,
        });
    });

    return scrapedData;
};


const parseTheMagicTutor = (html, searchTerm, url) => {
    const $ = cheerio.load(html);
    const textContent = $('xmp').text();
    const lines = textContent.split('\n');

    return lines.map(line => {
        const parts = line.split('\t').map(part => part.trim());
        if (parts.length < 2) return null;

        const name = parts[0];
        const price = parts[1];

        // Check if the line includes the search term and has a valid price
        if (name.toLowerCase().includes(searchTerm.toLowerCase()) && price !== '$ -') {
            const id = crypto.createHash('sha256').update(line).digest('hex');
            return {
                id,
                title: name,
                price: price,
                condition: ' ',
                expansion: ' ',
                imageUrl: '', // Placeholder or empty
                link: url // General link or empty
            };
        }
        return null;
    }).filter(card => card); // Filter out null entries
};

const scrapeMercadiaCityStore = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    $('.product-item').each((index, element) => {
        const title = $(element).find('.product-item-name').text().trim();
        // Extract and handle image URL
        const imageUrlElement = $(element).find('.main-img');
        
        const imageUrlRelative = imageUrlElement.attr('src');
        
        const condition = $(element).find('.product-item-description').text().trim();

        const imageUrl = imageUrlRelative ? (imageUrlRelative.startsWith('http') ? imageUrlRelative : `https:${imageUrlRelative}`) : 'No image';
        
        // Extract price (modify selector if necessary)
        const price = $(element).find('.price').text().trim() || '$No muestra precio'; // Replace with actual logic to extract price

        const link = $(element).find('.product-item-link').attr('href');
        const id = crypto.createHash('sha256').update($(element).html()).digest('hex');
        scrapedData.push({
            id,
            title,
            price,
            condition,
            expansion: ' ',
            imageUrl,
            link,
        });
    });

    return scrapedData;
};

const scrapeMadToyz = (html, url) => {
    const $ = cheerio.load(html);
    const scrapedData = [];
    $('.products .product').each((index, element) => {
        // Extract title
        const title = $(element).find('.woocommerce-loop-product__title').text().trim();
        const expansion = $(element).find('div a').text().trim();
        // Extract and handle image URL
        const imageUrlElement = $(element).find('img.swiper-lazy');
        const imageUrlRelative = imageUrlElement.attr('src');
        const imageUrl = imageUrlRelative ? (imageUrlRelative.startsWith('http') ? imageUrlRelative : `https:${imageUrlRelative}`) : 'No image';

        // Extract price
        let price = $(element).find('.onsale').text().trim().split(':')[1]?.replace(/[a-zA-Z]/g, '');
        
        if (!price) {
            price = $(element).find('bdi').text().trim()?.replace(/[a-zA-Z]/g, '');
        }
        if (!price) {
            price = '$No muestra precio';
        }
        price = `\$${price.replace('$','')}`
        // Log price for debugging
        
        const condition = $(element).find('.badge').text().trim();
        // Extract link
        const link = $(element).find('.woocommerce-LoopProduct-link').attr('href');
        
        const id = crypto.createHash('sha256').update($(element).html()).digest('hex');

        scrapedData.push({
            id,
            title,
            price,
            condition,
            expansion,
            imageUrl,
            link,
        });
    });

    return scrapedData;
};





module.exports = {
    scrapeMTGPirulo,
    scrapeMagicDealers,
    scrapeMagicLair,
    scrapeLaBatikuevaStore,
    parseTheMagicTutor,
    scrapeMercadiaCityStore,
    scrapeMadToyz
};
