import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";

export const saveData = async (data) => {
  try {
    const docRef = await addDoc(collection(db, "userActions"), data);
    console.log("Document written with ID: ", docRef.id);
  } catch (error) {
    console.error("Error adding document: ", error);
  }
};