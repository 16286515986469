import React, { useState, useEffect } from 'react';
const SavedList = ({ savedCards, updateSavedCards }) => {
    
    // Load saved cards from localStorage
    const loadSavedCards = () => {
        let savedData = localStorage.getItem('savedCards');
        savedData = savedData == 'undefined' ? null : savedData;
        const parsedData = savedData ? JSON.parse(savedData) : {}; // Verifica si es null/undefined
        updateSavedCards(parsedData); // Actualiza el estado con el contenido o un objeto vacío
    };

    // Load cards on mount and when updateSavedCards changes
    useEffect(() => {
        loadSavedCards();
    }, []); // Depend on updateSavedCards

    const removeFromSavedList = (vendor, cardId) => {
        if (!savedCards || typeof savedCards !== 'object') {
            console.error('savedCards no es válido:', savedCards);
            return;
        }
    
        const updatedSavedCards = { ...savedCards };
    
        if (!updatedSavedCards[vendor]) {
            console.warn(`Vendor "${vendor}" no encontrado en savedCards.`);
            return;
        }
    
        updatedSavedCards[vendor] = updatedSavedCards[vendor].filter((card) => card.id !== cardId);
    
        if (updatedSavedCards[vendor].length === 0) {
            delete updatedSavedCards[vendor];
        }
    
        try {
            localStorage.setItem('savedCards', JSON.stringify(updatedSavedCards));
            updateSavedCards(updatedSavedCards); // Siempre pasa el estado actualizado
        } catch (error) {
            console.error('Error actualizando savedCards:', error);
        }
    };
    

    return (
        <div className="saved-list">
            <h2>Cartas que quiero</h2>
            {Object.keys(savedCards).length > 0 ? (
                Object.keys(savedCards).map((vendor) => (
                    <div key={vendor} className="vendor-group">
                        <h3>{vendor}</h3>
                        {savedCards[vendor].map((card, index) => (
                            <div key={index} className="saved-card-item">
                                <a href={card.link} target="_blank" rel="noopener noreferrer">
                                    {card.title}
                                </a>
                                <span className="card-price">
                                    {card.currency || '$'}
                                    {card.price}
                                </span>
                                <button onClick={() => removeFromSavedList(vendor, card.id)}>Remove</button>
                            </div>
                        ))}
                    </div>
                ))
            ) : (
                <p>Nada que mostrar aún. Pero no te creo que no queres nada...</p>
            )}
        </div>
    );
};

export default SavedList;
