import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import cheerio from 'cheerio';
import placeholderImage from '../../buscaadorLogo.png'; // Imagen de carga
import './CardSearch.css';
import CardList from '../cardList/CardList'; // Import the CardList component
import {
  parseTheMagicTutor,
  scrapeLaBatikuevaStore,
  scrapeMTGPirulo,
  scrapeMagicDealers,
  scrapeMagicLair,
  scrapeMercadiaCityStore,
  scrapeMadToyz,
} from '../../scrappers/scraper'; // Import the scraper functions
import logoImage from '../../buscaadorLogo.png'; // Replace with actual path to your logo image
import bannerImage from '../../bannerCardSearch_cropped.png'; // Replace with actual path to your logo image
import { saveData } from '../../services/firestoreService';

const CardSearch = ({ 
    updateSavedCards,
    savedCards 
  }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [piruloCards, setPiruloCards] = useState([]);
  const [magicDealersCards, setMagicDealersCards] = useState([]);
  const [magicLairCards, setMagicLairCards] = useState([]);
  const [batikuevaCards, setBatikuevaCards] = useState([]);
  const [tutorCards, setTutorCards] = useState([]);
  const [mercadiaCityCards, setMercadiaCityCards] = useState([]);
  const [madToyzCards, setMadToyzCards] = useState([]);
  const [scryfallCard, setScryfallCard] = useState(null); // Añadido para Scryfall
  const [translateTerm, setTranslateTerm] = useState('');
  const [englishName, setEnglishName] = useState('');
  const [translationError, setTranslationError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [showFullPageLoader, setShowFullPageLoader] = useState(false);
  const searchInputRef = useRef(null);
  const [isListOpen, setIsListOpen] = useState(false);
  const [isTranslateOpen, setIsTranslateOpen] = useState(false);
  const [translationResults, setTranslationResults] = useState([]);
  const [dolarImageUrl, setDolarImageUrl] = useState(null);
  const [dolarLoading, setDolarLoading] = useState(false);
  const [dolarError, setDolarError] = useState(null);
  
  const PROXY_URL = 'https://corsproxy.io/'

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    message: '',
    providerStatus: {
      pirulo: false,
      magicDealers: false,
      magicLair: false,
      batikueva: false,
      tutor: false,
      mercadiaCity: false,
      madToyz: false,
      scryfall: false, // Añadido para Scryfall
    },
  });

  const [loadingPiruloState, setLoadingPiruloState] = useState(false);
  const [loadingMagicDealersState, setLoadingMagicDealersState] = useState(false);
  const [loadingBatikuevaState, setLoadingBatikuevaState] = useState(false);
  const [loadingMagicLairState, setLoadingMagicLairState] = useState(false);
  const [loadingTutorState, setLoadingTutorState] = useState(false);
  const [loadingMercadiaCityState, setLoadingMercadiaCityState] = useState(false);
  const [loadingMadToyzState, setLoadingMadToyzState] = useState(false);
  const [loadingScryfallState, setLoadingScryfallState] = useState(false); // Añadido para Scryfall

  const [showDollar, setShowDollar] = useState(false);

  const [selectedProviders, setSelectedProviders] = useState({
    pirulo: true,
    magicDealers: true,
    magicLair: true,
    batikueva: true,
    tutor: true,
    mercadiaCity: true,
    madToyz: true,
    scryfall: true, // Añadido para Scryfall
  });

  const fetchDolarImage = async () => {
    setDolarLoading(true);
    setDolarError(null);
    try {
      // Usando AllOrigins para evitar CORS
      const targetUrl = encodeURIComponent('https://www.mtgpirulo.com/'); // Reemplaza con la URL correcta si es necesario
      const response = await axios.get(`${PROXY_URL}${targetUrl}`);
      const html = response.data;
      const $ = cheerio.load(html);
      const dolarSrc = $('header.site .logo img').attr('src');

      if (dolarSrc) {
        // Convertir a URL absoluta si es relativa
        const absoluteDolarImage = dolarSrc.startsWith('http') ? dolarSrc : `https://www.mtgpirulo.com${dolarSrc}`;
        setDolarImageUrl(absoluteDolarImage);
      } else {
        throw new Error('Imagen del dólar no encontrada');
      }
    } catch (error) {
      console.error('Error obteniendo la imagen del dólar:', error);
      setDolarError('No se pudo obtener la cotización del dólar.');
    } finally {
      setDolarLoading(false);
    }
  };

  useEffect(() => {
    fetchDolarImage();
  }, []);


  const toggleDollar = () => {
    setShowDollar(!showDollar);
  };
  
  const PIRULO_URL = 'https://www.mtgpirulo.com/products/search?c=8&q=';
  const DEALERS_URL = 'https://www.magicdealersstore.com/products/search?c=1&q=';
  const BATIKUEVA_URL = 'https://www.labatikuevastore.com/search/?q=';
  const LAIR_URL = 'https://magic-lair.myshopify.com/search?page=1&q=';
  const TUTOR_URL = 'https://www.themagictutor.com/cartas/cartas.html';
  const MERCADIA_CITY_URL = 'https://mercadiacity.com/index.php/catalogsearch/result/?q=';
  const TUTOR_WEBSITE = 'https://www.themagictutor.com/';
  const MADTOYZ_URL = 'https://madtoyzjugueteria.com/?instock=on&post_type=product&mtg=on&s=';

  const loadingMessages = [
    'Fetcheando de las casas de magic...',
    'Casteando la lista de precios...',
  ];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
        setIsListOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleTranslate = async () => {
    if (translateTerm) {
      // Resetear estados antes de la solicitud
      setTranslationError(null);
      setTranslationResults([]);
      setEnglishName('');
      try {

        postFirestore('translate', translateTerm, {} );

        const query = 'lang:any ' + translateTerm;
        const response = await axios.get(
          `https://api.scryfall.com/cards/search?order=name&q=${encodeURIComponent(query)}`
        );
        if (response.status === 200) {
          const cards = response.data.data;
          if (cards.length > 0) {
            if (cards.length === 1) {
              // Solo un resultado
              setEnglishName(cards[0].name);
              setTranslationResults([]);
              setSearchTerm(cards[0].name); // Autocompleta la barra de búsqueda
            } else {
              // Múltiples resultados
              setTranslationResults(cards);
              setEnglishName('');
            }
            setTranslationError(null);
          } else {
            // No se encontraron cartas
            setTranslationResults([]);
            setEnglishName('');
            setTranslationError('No se encontraron cartas.');
          }
        } else {
          // Respuesta no exitosa
          setTranslationResults([]);
          setEnglishName('');
          setTranslationError('Error al buscar cartas.');
        }
      } catch (error) {
        console.error('Error fetching translations:', error);
        setTranslationResults([]);
        setEnglishName('');
        setTranslationError('WAT? Ni Scryfall sabe qué carta es esa.');
      }
    }
  };
  
  

  const handleItemClick = (suggestion) => {
    setSearchTerm(suggestion);
    setSuggestions([]);
  };

  const handleTranslationClick = (cardName) => {
    setSearchTerm(cardName); // Autocompleta la barra de búsqueda
    setEnglishName(cardName);
    setTranslationResults([]); // Limpia los resultados de traducción
  };
   

  const handleProviderCheckbox = (provider) => {
    setSelectedProviders((prevState) => ({
      ...prevState,
      [provider]: !prevState[provider],
    }));
  };

  const handleChange = async (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (value.length === 0) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await axios.get(
        `https://api.scryfall.com/cards/autocomplete?q=${encodeURIComponent(value)}`
      );
      setSuggestions(response.data.data);
    } catch (error) {
      console.error('Error fetching autocomplete suggestions:', error);
    }
  };

  // Función para obtener datos de Scryfall
  const fetchScryfallData = async (cardName) => {
    try {
      const response = await axios.get(
        `https://api.scryfall.com/cards/named?fuzzy=${encodeURIComponent(cardName)}`
      );
      if (response.status === 200) {
        const cardData = response.data;
        return cardData;
      }
    } catch (error) {
      console.error('Error fetching data from Scryfall:', error);
    }
    return null;
  };
  const postFirestore = async (action, input, selections) => {
    console.log('Abount to sabe action:', action, 'input:', input, 'selections:', selections);
    const data = {
      action,
      input,
      timestamp: new Date().toISOString(),
      selections,
      sessionId: localStorage.getItem("sessionId"), // Use this to identify the session/computer
    };

    saveData(data);
  };

  const handleSearch = async () => {
    const randomMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
    setShowFullPageLoader(true);

    if (searchTerm) {
      if (selectedProviders.pirulo) setLoadingPiruloState(true);
      if (selectedProviders.magicDealers) setLoadingMagicDealersState(true);
      if (selectedProviders.batikueva) setLoadingBatikuevaState(true);
      if (selectedProviders.magicLair) setLoadingMagicLairState(true);
      if (selectedProviders.tutor) setLoadingTutorState(true);
      if (selectedProviders.mercadiaCity) setLoadingMercadiaCityState(true);
      if (selectedProviders.madToyz) setLoadingMadToyzState(true);
      if (selectedProviders.scryfall) setLoadingScryfallState(true); // Añadido para Scryfall
      
      const piruloURL = `${PROXY_URL}${encodeURIComponent(
        `https://www.mtgpirulo.com/products/search?q=${encodeURIComponent(searchTerm)}&c=8&_=${Date.now()}`
      )}`;
      const magicDealersURL = `${PROXY_URL}${encodeURIComponent(
        `https://www.magicdealersstore.com/products/search?q=${encodeURIComponent(searchTerm)}&c=1&_=${Date.now()}`
      )}`;
      const batikuevaURL = `${PROXY_URL}${encodeURIComponent(
        `https://www.labatikuevastore.com/search/?q=${encodeURIComponent(searchTerm)}&_=${Date.now()}`
      )}`;
      const magicLairURL = `${PROXY_URL}${encodeURIComponent(
        `https://magic-lair.myshopify.com/search?page=1&q=${encodeURIComponent(searchTerm)}&_=${Date.now()}`
      )}`;
      const tutorURL = `${PROXY_URL}${encodeURIComponent(
        `https://www.themagictutor.com/cartas/cartas.html?_=${getCurrentTimestamp()}`
      )}`;
      const mercadiaCityURL = `${PROXY_URL}${encodeURIComponent(
        `https://mercadiacity.com/index.php/catalogsearch/result/?q=${encodeURIComponent(searchTerm)}&_=${getCurrentTimestamp()}`
      )}`;
      const madToyzURL = `${PROXY_URL}${encodeURIComponent(
        `https://madtoyzjugueteria.com/?instock=on&post_type=product&mtg=on&s=${encodeURIComponent(searchTerm)}&_=${getCurrentTimestamp()}`
      )}`;

      const requests = [];
      // Añadir solicitudes a los proveedores seleccionados
      postFirestore('search', searchTerm, selectedProviders );

      if (selectedProviders.pirulo) {
        requests.push(
          axios
            .get(piruloURL)
            .then((response) => {
              const piruloCards = scrapeMTGPirulo(response.data, PIRULO_URL + encodeURIComponent(searchTerm)) || [];
              setPiruloCards(piruloCards);
              setLoadingPiruloState(false);
            })
            .catch((error) => {
              console.error('Error fetching Pirulo:', error);
              setPiruloCards([]);
              setLoadingPiruloState(false);
            })
        );
      }

      if (selectedProviders.magicDealers) {
        requests.push(
          axios.get(magicDealersURL)
            .then(response => {
              const magicDealersCards = scrapeMagicDealers(response.data, DEALERS_URL + encodeURIComponent(searchTerm)) || [];
              setMagicDealersCards(magicDealersCards);
              setLoadingMagicDealersState(false);
            })
            .catch(error => {
              console.error('Error fetching Magic Dealers:', error);
              setMagicDealersCards([]);
              setLoadingMagicDealersState(false);
            })
        );
      }
  
      if (selectedProviders.batikueva) {
        requests.push(
          axios.get(batikuevaURL)
            .then(response => {
              const batikuevaCards = scrapeLaBatikuevaStore(response.data) || [];
              setBatikuevaCards(batikuevaCards);
              setLoadingBatikuevaState(false);
            })
            .catch(error => {
              console.error('Error fetching Batikueva:', error);
              setBatikuevaCards([]);
              setLoadingBatikuevaState(false);
            })
        );
      }
  
      if (selectedProviders.magicLair) {
        requests.push(
          axios.get(magicLairURL)
            .then(response => {
              const magicLairCards = scrapeMagicLair(response.data) || [];
              setMagicLairCards(magicLairCards);
              setLoadingMagicLairState(false);
            })
            .catch(error => {
              console.error('Error fetching Magic Lair:', error);
              setMagicLairCards([]);
              setLoadingMagicLairState(false);
            })
        );
      }
  
      if (selectedProviders.tutor) {
        requests.push(
          axios.get(tutorURL)
            .then(response => {
              const tutorCards = parseTheMagicTutor(response.data, searchTerm, TUTOR_URL);
              setTutorCards(tutorCards);
              setLoadingTutorState(false);
            })
            .catch(error => {
              console.error('Error fetching Tutor:', error);
              setTutorCards([]);
              setLoadingTutorState(false);
            })
        );
      }
  
      if (selectedProviders.mercadiaCity) {
        requests.push(
          axios.get(mercadiaCityURL)
            .then(response => {
              const mercadiaCityCards = scrapeMercadiaCityStore(response.data) || [];
              setMercadiaCityCards(mercadiaCityCards);
              setLoadingMercadiaCityState(false);
            })
            .catch(error => {
              console.error('Error fetching Mercadia City:', error);
              setMercadiaCityCards([]);
              setLoadingMercadiaCityState(false);
            })
        );
      }
  
      if (selectedProviders.madToyz) {
        requests.push(
          axios.get(madToyzURL)
            .then(response => {
              const madToyzCards = scrapeMadToyz(response.data) || [];
              setMadToyzCards(madToyzCards);
              setLoadingMadToyzState(false);
            })
            .catch(error => {
              console.error('Error fetching Mad Toyz:', error);
              setMadToyzCards([]);
              setLoadingMadToyzState(false);
            })
        );
      }

      if (selectedProviders.scryfall) {
        requests.push(
          fetchScryfallData(searchTerm).then((cardData) => {
            if (cardData) {
              setScryfallCard(cardData);
              setLoadingScryfallState(false);
            } else {
              setScryfallCard(null);
              setLoadingScryfallState(false);
            }
          })
        );
      }

      try {
        await Promise.all(requests);
      } catch (error) {
        console.error('Error in Promise.all:', error);
      } finally {
        setLoadingState((prevState) => ({
          ...prevState,
          isLoading: false,
          message: '',
          providerStatus: {
            pirulo: false,
            magicDealers: false,
            magicLair: false,
            batikueva: false,
            tutor: false,
            mercadiaCity: false,
            madToyz: false,
            scryfall: false,
          },
        }));
        setShowFullPageLoader(false);
      }
    }
  };

  const calculateProgressWidth = (providerStatus) => {
    const totalProviders = Object.keys(providerStatus).length;
    const loadedProviders = Object.values(providerStatus).filter((status) => !status).length;
    return (loadedProviders / totalProviders) * 100;
  };

  const getCurrentTimestamp = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = `0${currentDate.getMonth() + 1}`.slice(-2); // Add 1 to month (January is 0)
    const day = `0${currentDate.getDate()}`.slice(-2);
    const hour = `0${currentDate.getHours()}`.slice(-2);

    return `${year}-${month}-${day}_${hour}h`;
  };

  return (
    <div className="container">
      {loadingState.isLoading && (
        <div className="loader-overlay">
          <div className="loader-progress-bar">
            <div
              className="loader-progress-bar-inner"
              style={{ width: calculateProgressWidth(loadingState.providerStatus) + '%' }}
            ></div>
          </div>
          <img src={logoImage} alt="Loading..." className="loader-image" />
          <p className="loader-message">{loadingState.message}</p>
        </div>
      )}
      <div className="header header-with-title banner-container">
        <div className="banner">
          <img src={bannerImage} alt="Banner" />
        </div>
        <h1 className="main-title">Alto buscador de Cartas Magic</h1>
      </div>
      <div className="translate-toggle">
        <button onClick={() => setIsTranslateOpen(!isTranslateOpen)} className="button">
          {isTranslateOpen ? '▼ Traductor de cartas' : '▶ Traductor de cartas'}
        </button>
      </div>
      {/* Botón para redirigir al sitio de Notion
      <div className="notion-button-container">
        <button
          className="button"
          onClick={() => window.open('https://courageous-flower-b8b.notion.site/Desarrollo-del-buscador-11d5075fdfb7800493bbcd35c42c944a?pvs=4', '_blank')}
        >
          Sugerime una funcionalidad
        </button>
      </div> */}
      <div className={`translate-container ${isTranslateOpen ? 'open' : ''}`}>
        <h2>Escribí esa carta que no tenés ni idea cómo se llama en inglés</h2>
        <input
          type="text"
          placeholder="Meté acá el nombre de la carta en el idioma que quieras"
          value={translateTerm}
          onChange={(e) => setTranslateTerm(e.target.value)}
          className="search-input translate-input"
        />
        <button onClick={handleTranslate} disabled={translateTerm.length === 0} className="button">
          Traducí pa'
        </button>
        {translationError && <p className="error-message">{translationError}</p>}

        {/* Mostrar resultado único */}
        {englishName && (
          <p>
            La carta "<strong>{translateTerm}</strong>" se llama "<strong>{englishName}</strong>".
          </p>
        )}

        {/* Mostrar lista de traducciones si hay múltiples resultados */}
        {translationResults.length > 1 && (
          <div className="translation-results">
            <p>Seleccioná la carta que estás buscando:</p>
            <ul>
              {translationResults.map((card) => (
                <li key={card.id} onClick={() => handleTranslationClick(card.name)}>
                  {card.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="search-container">
        <input
          type="text"
          placeholder="Buscar cartas de Magic: The Gathering"
          value={searchTerm}
          onChange={handleChange}
          className="search-input"
          ref={searchInputRef}
        />
        <div className="suggestions-container">
          {suggestions.map((suggestion, index) => (
            <div key={index} onClick={() => handleItemClick(suggestion)} className="suggestion-item">
              {suggestion}
            </div>
          ))}
        </div>
        <div className="provider-checkboxes">
          {Object.entries(selectedProviders).map(([provider, isSelected]) => (
            <label key={provider} className="provider-checkbox-label">
              <input
                type="checkbox"
                className="provider-checkbox"
                checked={isSelected}
                onChange={() => handleProviderCheckbox(provider)}
              />
              {provider === 'scryfall' ? 'Comparar precios USA' : provider}
            </label>
          ))}
        </div>
        <button onClick={handleSearch} disabled={loadingState.isLoading || searchTerm.length === 0} className="button">
          {loadingState.isLoading ? 'Buscando...' : 'Buscar'}
        </button>
        <button className="button" onClick={toggleDollar}>
          {!showDollar ? '▶ Mostrar cotización del dólar' : '▼ Ocultar cotización del dólar'}
        </button>
      </div>

      {/* <div className="iframe-container dollar" style={{ display: showDollar ? 'block' : 'none' }}>
        <iframe src="https://dolarhoy.com/i/cotizaciones/dolar-blue" frameBorder="0"></iframe>
        <iframe src="https://dolarhoy.com/i/cotizaciones/dolar-contado-con-liquidacion" frameBorder="0"></iframe>
      </div> */}
      {/* Nueva Sección para la Imagen del Dólar */}
      {showDollar && (
        <div className="dolar-container">
          {dolarLoading ? (
            <div className="dolar-loading">
              <img src={placeholderImage} alt="Cargando..." className="loader-image" />
              <p>Cargando cotización del dólar...</p>
            </div>
          ) : dolarError ? (
            <p className="error-message">{dolarError}</p>
          ) : dolarImageUrl ? (
            <img
              src={dolarImageUrl}
              alt="Cotización del Dólar - Pirulo MTG"
              className="dolar-image"
            />
          ) : null}
        </div>
      )}
      {/* Mostrar los resultados de Scryfall */}
      {selectedProviders.scryfall && scryfallCard && (
        <div className="card-list-container">
          <h2 className="card-list-title">Precio de Referencia (Scryfall)</h2>
          <div className="scryfall-card">
            <p>
              <strong>{scryfallCard.name}</strong>
            </p>
            <p>Precio USD: {scryfallCard.prices.usd ? `$${scryfallCard.prices.usd}` : 'N/A'}</p>
            <p>Precio Foil USD: {scryfallCard.prices.usd_foil ? `$${scryfallCard.prices.usd_foil}` : 'N/A'}</p>
            <p>Precio EUR: {scryfallCard.prices.eur ? `€${scryfallCard.prices.eur}` : 'N/A'}</p>
            <p>Precio Foil EUR: {scryfallCard.prices.eur_foil ? `€${scryfallCard.prices.eur_foil}` : 'N/A'}</p>
          </div>
        </div>
      )}

      {/* Aquí añades tus CardList como en tu código original */}
      {/* Por ejemplo: */}
      {selectedProviders.pirulo && (
        <CardList
          title="Pirulo Game Store"
          cards={piruloCards}
          currencySymbol="USD " // Example: Argentine Peso symbol
          exchangeRate="" // Example exchange rate
          emptyMessage="No che, nada"
          searchUrl={PIRULO_URL + encodeURIComponent(searchTerm)}
          usePlaceholder={false}
          isLoading={loadingPiruloState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}
      {selectedProviders.magicDealers && (
        <CardList
          title="Magic Dealers"
          cards={magicDealersCards}
          currencySymbol="ARS $" // Example: Argentine Peso symbol
          exchangeRate="" // Example exchange rate
          emptyMessage="No encontramo'"
          searchUrl={DEALERS_URL + encodeURIComponent(searchTerm)}
          usePlaceholder={false}
          isLoading={loadingMagicDealersState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}
      {selectedProviders.batikueva && (
        <CardList
          title="La Batikueva"
          cards={batikuevaCards}
          currencySymbol="ARS $" // Example: Argentine Peso symbol
          exchangeRate="" // Example exchange rate
          emptyMessage="No hubo suerte"
          searchUrl={BATIKUEVA_URL + encodeURIComponent(searchTerm)}
          usePlaceholder={false}
          isLoading={loadingBatikuevaState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}
      {selectedProviders.magicLair && (
        <CardList
          title="Magic Lair"
          cards={magicLairCards}
          currencySymbol="ARS $" // Example: Argentine Peso symbol
          exchangeRate="" // Example exchange rate
          emptyMessage="Nada de nada"
          searchUrl={LAIR_URL + encodeURIComponent(searchTerm)}
          usePlaceholder={false}
          isLoading={loadingMagicLairState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}
      {selectedProviders.tutor && (
        <CardList
          title="The Magic Tutor"
          cards={tutorCards}
          currencySymbol="$" // Adjust as necessary
          emptyMessage="No cartas found"
          searchUrl={TUTOR_WEBSITE}
          usePlaceholder={true}
          isLoading={loadingTutorState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}
      {selectedProviders.mercadiaCity && (
        <CardList
          title="Mercadia City"
          cards={mercadiaCityCards}
          currencySymbol="ARS $" // Example: Argentine Peso symbol
          exchangeRate="" // Example exchange rate
          emptyMessage="Nop, no hay nada acá"
          searchUrl={MERCADIA_CITY_URL + encodeURIComponent(searchTerm)}
          usePlaceholder={false}
          isLoading={loadingMercadiaCityState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}
      {selectedProviders.madToyz && (
        <CardList
          title="Mad Toyz"
          cards={madToyzCards}
          currencySymbol="USD " // Example: Argentine Peso symbol
          exchangeRate="" // Example exchange rate
          emptyMessage="Nop, no hay nada acá"
          searchUrl={MADTOYZ_URL + encodeURIComponent(searchTerm)}
          usePlaceholder={false}
          isLoading={loadingMadToyzState}
          updateSavedCards={updateSavedCards} // Pass the function here,
          savedCards = {savedCards}
          placeholderImage={placeholderImage}

        />
      )}

      <div className="thanks">
        <p>*No se están contemplando todos los precios que tiene una misma carta en base a su condición.</p>
        <p>
          Si encontraste lo que buscabas y te ahorraste unos pesitos, ya me doy por hecho. Si te ahorraste muchos pesitos y
          estás contento, invítame un Sol Ring así me ayudas a seguir viciando.
        </p>
        <p>
        👉 <a href='https://forms.gle/DAYurgqvv9D8BWTZ6'> Reporta un error o pedime una funcionalidad </a> 👈
        </p>
        
      </div>
      <a href="https://cafecito.app/altobuscadormtg" rel="noopener" target="_blank">
        <img
          srcSet="https://cdn.cafecito.app/imgs/buttons/button_3.png 1x, https://cdn.cafecito.app/imgs/buttons/button_3_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_3_3.75x.png 3.75x"
          src="https://cdn.cafecito.app/imgs/buttons/button_3.png"
          alt="Invitame un Sol Ring con cafecito.app"
        />
      </a>
    </div>
  );
};

export default CardSearch;
