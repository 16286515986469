import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Box } from '@mui/material';
import { styled } from '@mui/system';
import './StatisticsPage.css'; // Importa el archivo CSS

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  background: 'linear-gradient(135deg, #1f2937, #3b82f6)',
  color: '#fff',
  borderRadius: '12px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  marginBottom: theme.spacing(2),
}));

const StatisticsPage = () => {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({});
  const [topCards, setTopCards] = useState([]);
  const [todaySearches, setTodaySearches] = useState([]);
  const [userStats, setUserStats] = useState({}); // Nuevos datos de usuario

  useEffect(() => {
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'userActions'));
        const dataArray = querySnapshot.docs.map(doc => doc.data());
        setData(dataArray);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const selections = [
        'batikueva', 'madToyz', 'magicDealers', 'magicLair',
        'mercadiaCity', 'pirulo', 'scryfall', 'tutor',
      ];
      const totals = selections.reduce((acc, key) => {
        acc[key] = data.filter(item => item.selections[key]).length;
        return acc;
      }, {});
      setTotals(totals);

      const now = new Date();
      const ranges = {
        last30Days: now.setDate(now.getDate() - 30),
      };
      const filteredData = data.filter(item => new Date(item.timestamp).getTime() >= ranges.last30Days);
      const cardCount = filteredData.reduce((acc, item) => {
        acc[item.input] = (acc[item.input] || 0) + 1;
        return acc;
      }, {});
      const sortedCards = Object.entries(cardCount)
        .sort((a, b) => b[1] - a[1])
        .slice(0, 10);
      setTopCards(sortedCards);

      const today = new Date().toISOString().split('T')[0];
      const todayData = data.filter(item => item.timestamp.startsWith(today));
      setTodaySearches(todayData);

      // Cálculos de métricas por usuario con poda
      const sessionMap = data.reduce((acc, item) => {
        const sessionId = item.sessionId;
        acc[sessionId] = (acc[sessionId] || 0) + 1;
        return acc;
      }, {});

      const sessionCounts = Object.values(sessionMap).sort((a, b) => a - b);
      const totalSearches = sessionCounts.reduce((sum, count) => sum + count, 0);

      const trimmedCounts = sessionCounts.slice(
        Math.ceil(sessionCounts.length * 0.1),
        Math.floor(sessionCounts.length * 0.9)
      );

      const averageSearches = (trimmedCounts.reduce((sum, count) => sum + count, 0) / trimmedCounts.length).toFixed(2);
      const maxSearches = Math.max(...sessionCounts);
      const singleSearchUsers = sessionCounts.filter(count => count === 1).length;

      setUserStats({
        averageSearches,
        maxSearches,
        singleSearchUsers,
      });
    }
  }, [data]);

  return (
    <Box className="statistics-page" sx={{ p: 4, minHeight: '100vh' }}>
      <Typography variant="h3" gutterBottom sx={{ fontWeight: 700, textAlign: 'center', color: '#3b82f6' }}>
        Dashboard de Estadísticas
      </Typography>

      <Grid container spacing={4}>
        {/* Totals Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <SectionTitle>Totales por Selección</SectionTitle>
            <ul>
              {Object.entries(totals)
                .sort((a, b) => b[1] - a[1]) // Ordena por valor de forma descendente
                .map(([key, value]) => (
                  <li key={key}>{key}: {value}</li>
                ))}
            </ul>
          </StyledPaper>
        </Grid>

        {/* Top 5 Cards Section */}
        <Grid item xs={12} md={6}>
          <StyledPaper>
            <SectionTitle>Top 10 Cartas Más Buscadas (Últimos 30 Días)</SectionTitle>
            <ol>
              {topCards.map(([card, count]) => (
                <li key={card}>{card}: {count} veces</li>
              ))}
            </ol>
          </StyledPaper>
        </Grid>

        {/* User Statistics Section */}
        <Grid item xs={12}>
          <StyledPaper>
            <SectionTitle>Estadísticas de Usuarios</SectionTitle>
            <ul>
              <li>Búsquedas promedio por usuario (últimos 7 días, con poda): {userStats.averageSearches || 0}</li>
              <li>Cantidad máxima de búsquedas por usuario (últimos 7 días): {userStats.maxSearches || 0}</li>
              <li>Usuarios con solo 1 búsqueda (últimos 7 días): {userStats.singleSearchUsers || 0}</li>
            </ul>
          </StyledPaper>
        </Grid>
      </Grid>

      {/* Today's Searches Table */}
      <Box mt={4}>
        <StyledPaper>
          <SectionTitle>Búsquedas de Hoy: {todaySearches.length}</SectionTitle>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Input</strong></TableCell>
                  <TableCell><strong>Action</strong></TableCell>
                  <TableCell><strong>Session Id</strong></TableCell>
                  <TableCell><strong>Selections</strong></TableCell>
                  <TableCell><strong>Timestamp</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {todaySearches.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.input}</TableCell>
                    <TableCell>{item.action}</TableCell>
                    <TableCell>{item.sessionId}</TableCell>
                    <TableCell>{JSON.stringify(item.selections)}</TableCell>
                    <TableCell>{item.timestamp}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledPaper>
      </Box>
    </Box>
  );
};

export default StatisticsPage;
