import React, { useEffect, useState } from 'react';
import CardSearch from './components/cardSearch/CardSearch';
import SavedList from './components/savedList/SavedList';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom'; // Added Link for navigation
import StatisticsPage from './pages/StatisticsPage';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';
import 'dotenv/config';
import './App.css';

const App = () => {
  const [savedCards, setSavedCards] = useState(() => {
    const sessionId = localStorage.getItem("sessionId") || crypto.randomUUID();
    localStorage.setItem("sessionId", sessionId);

    let savedData = localStorage.getItem('savedCards');
    savedData = savedData === 'undefined' ? null : savedData;
    return savedData ? JSON.parse(savedData) : {}; // Verifica si es null/undefined
  });
  const [showSidebar, setShowSidebar] = useState(false);

  // Update saved cards in localStorage and state
  const updateSavedCards = (updatedCards) => {
    try {
      localStorage.setItem('savedCards', JSON.stringify(updatedCards));
      setSavedCards(updatedCards);
    } catch (error) {
      console.error('Error updating savedCards:', error);
    }
  };

  useEffect(() => {
    const handleStorageChange = () => {
      try {
        let savedData = localStorage.getItem('savedCards');
        savedData = savedData === 'undefined' ? null : savedData;
        const updatedCards = JSON.parse(savedData) || {};
        setSavedCards(updatedCards);
      } catch (error) {
        console.error('Error parsing savedCards from localStorage:', error);
        setSavedCards({});
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  return (
    <Router>
      <div className="App">
        {/* <nav className="navigation">
          <Link to="/" className="nav-link">Card Search</Link>
          <Link to="/statistics" className="nav-link">Statistics</Link>
        </nav> */}

        <button
          className="toggle-sidebar-button"
          data-count={Object.values(savedCards).reduce((acc, vendorCards) => acc + vendorCards.length, 0)}
          onClick={() => setShowSidebar(!showSidebar)}
        >
          {showSidebar ? '×' : '☰'}
        </button>

        <div className={`sidebar ${showSidebar ? 'sidebar-open' : ''}`}>
          <SavedList savedCards={savedCards} updateSavedCards={updateSavedCards} />
        </div>

        <Routes>
          <Route
            path="/"
            element={<CardSearch savedCards={savedCards} updateSavedCards={updateSavedCards} />}
          />
          <Route path="/statistics" element={<StatisticsPage />} />
        </Routes>

        <SpeedInsights />
        <Analytics />
      </div>
    </Router>
  );
};

export default App;
